.registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-top: 5%;
}
  .headingStyle{
    display: flex;
    flex-direction: row;
  }
  .p{
    margin-top: -2%;
    color: #6D6D6D;
    font-weight: 500;
  }

  .admin {
    color: #D68B43;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    font-size: 46px;
    margin-right: 10px;
  }
  .reg {
    color: black;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    font-size: 46px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:3rem 0rem;
    margin-inline: 200px;
    width: 60%;
  }
  
  .input-group{
    /* margin: 20px 20px; */
    width: 100%;
    margin:0px
  }

  .reg-div-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  input {
    width: 90%;
    padding: 5px;
    border: none; /* Remove default border */
    border-bottom: 0.1px solid #9EA3A2; /* Add bottom border in grey color */
    color: grey;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 19px;
    font-weight: 300;
  }
  input:focus {
    outline: none; /* Remove default focus outline */
    border-bottom: 1px solid #ccc; /* Maintain bottom border in grey color */
  }

  .row input::placeholder{
    color: #9EA3A2;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 19px;
    font-weight: 300;
  }
  
  .belowButton {
    font-weight: 400;
    width: 40%;
    justify-content: center;
    align-items: center;

  }

  .belowButton p{
    margin-top: 1%;
    font-size: 11px;
    color: #6D6D6D;
    font-weight: 400;
    width: 60%;
    align-items: center;
    text-align: center;
    margin-left: 20%;
  }
  .link {
    color: #6D6D6D; /* Color of the links */
    text-decoration: underline; /* Underline the links */
    cursor: pointer;
    margin-right: 3px; 
    margin-left: 3px; /* Show pointer cursor on hover */
  }
  
  .link:hover {
    color: #6D6D6D; /* Change color on hover */
  }

  button{
    background-color: #FDC58F;
    border: none;
    padding: 17px 90px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  @media screen and (max-width:600px){
    .reg-div-wrapper{
       margin-top: 2rem;
    }
    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin:0rem;
      margin-inline: 200px;
      width: 85%;
    }
    input {
     margin-bottom: 3rem;
    }
    .reg-p-tag{
      width: 100%;
    }
  }