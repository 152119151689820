.footer {
    background-color: #ffffff; /* Example background color */
    padding: 20px 0; /* Adjust padding as needed */
    text-align: center; /* Center align text */
  }
  
  .container {
    width: 100%; /* Full width */
    max-width: 1200px; /* Maximum width */
    margin: 0 auto; /* Center the container */
  }
  .text-center{
    font-weight:500;
    font-size: '25px';
  }
  
  /* Adjust the position of the footer */
  /* Add this CSS to your PaymentScreen.css file */
.footer {
    /* width: 100%; */
    background-color: #ffffff; /* Adjust background color as needed */
    padding: 20px; /* Adjust padding as needed */
    text-align: center;
  }
  
  