/* styles.css */

.cmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent gray background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .cmodal {
    background-color: white;
    padding-inline: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .cclose-btn {
    background-color: white;
    top: 0px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
  }
  
  .cclose-btn:hover {
    color: red;
  }
  
  .crows {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-block: 20px;
  }
  
  .cinput-groups{
    /* margin: 20px 20px; */
    /* background-color: green;
    margin-left: -100px; */
    border: 2px solid grey;
    border-radius: 5px;
  }
  input::placeholder{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 15px;
    font-weight:100;
    color: #626262;
  }
  .cbuttons {
    align-self: center;
    width: 100%;
    border: 1px solid black;
    margin-bottom: 30px;
  }

  .cmodal-head-wrapper{
    display:flex; 
    flex-direction:row;
     justify-content:space-between;
     align-items:center
    }

  @media screen and (max-width:1050px){
    .cmodal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height:100%;
      background-color: rgba(0, 0, 0, 0.5); /* Transparent gray background */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    .crows {
      display: flex;
      flex-direction: column;
      justify-content:center;
      margin-block: 0px;
      margin-top : 0px;
    }
    .cinput-groups input{
      margin-top: 2rem;
      width: 100%;
      background-color: aqua;
    }
  }



  @media screen and (max-width:600px){
    .modal-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height:auto;
      background-color: rgba(0, 0, 0, 0.5); /* Transparent gray background */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
  }
  .c-dropdown {
    position: relative; /* Set position to relative */
    width: 27rem;
    padding-inline: 15px;
    padding-block: 12px;
    border-radius: 4px;
    border: none;

    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
  .c-dropdown1 {
    position: relative; /* Set position to relative */
    width: 57rem;
    padding-inline: 15px;
    padding-block: 12px;
    border-radius: 4px;
    border: none;
    /* background-color: transparent; */
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
  .c-textArea {
    position: relative; /* Set position to relative */
    width: 27rem;
    padding-inline: 15px;
    padding-block: 12px;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    appearance: none; /* Remove default dropdown arrow */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; 
    font-size: 16px;
    color: #333;
  }
  
  .c-dropdown::after {
    content: '\25BC'; /* Unicode for down arrow */
    content-visibility:visible;
    position: absolute; /* Position the icon */
    top: calc(50% - 8px);
    right: 10px;
    pointer-events: none;
    border: none;
  }
  
  /* Add icon styles here */
  
  .c-dropdown:focus {
    outline: none; /* Remove outline when focused */
  }
  .c-dropdown1:focus {
    outline: none; /* Remove outline when focused */
  }
  textarea:focus {
    outline: none;
  }
  
  /* Style dropdown arrow */
  