.registration-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6%;
    width: 100%;
    /* background-color: #D68B43; */
}
  .headingStyle1{
    display: flex;
    flex-direction: row;
  }
  .p1{
    margin-top: -2%;
    color: #6D6D6D;
    font-weight: 500;
  }

  .admin1 {
    color: #D68B43;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    font-size: 38px;
    margin-right: 10px;
  }
  .reg1 {
    color: black;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    font-size: 38px;
  }
  

  .pass-input-group1{
    width: 100%;
  }
  .pass-input-grouppass{
    width: 100%;
  }

  .pass-input-group21{
    width: 100%;
  }

  .pass-input-group1 input {
    width: 90%;
  }

  .pass-input-group21 input {
    width: 90%;
    margin-bottom: 15%;
  }
  
  label {
    font-size: 13px;
    font-weight: 600;
  }

  input::placeholder{
    color: #9EA3A2;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: 200;
  }
  
  .belowButton1 {
    font-weight: 400;
    width: 40%;
    justify-content: center;
    align-items: center;

  }

  .belowButton1 p{
    margin-top: 1%;
    font-size: 11px;
    color: #6D6D6D;
    font-weight: 400;
    width: 60%;
    align-items: center;
    text-align: center;
    margin-left: 20%;
  }

  .sendOTP{
    margin-left: 220px;
    background-color:wheat;
    border: none;
    padding: 3px 10px;
    font-weight: 600;
    cursor: pointer;
  }
  
 

  @media screen and (max-width:600px){
    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-inline: 200px;
      width: 85%;
      margin:0rem;
    }
    .belowButton1 p{
      width: 100%;
      margin-left:0%;
    }

    .pass-input-group1{
      margin: 2rem 0rem;
    }

    .pass-input-group1 input{
      margin-bottom: 0rem;
      width: 100%;
    }
    .pass-input-group21 input{
      width: 100%;
    }
  }