/* styles.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent gray background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: white;
    padding-inline: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .close-btn {
    background-color: white;
    top: 0px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
  }
  
  .close-btn:hover {
    color: red;
  }
  
  .rows {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-block: 20px;
    margin-top : 50px;
  }
  
  .input-groups{
    /* margin: 20px 20px; */
    /* background-color: green;
    margin-left: -100px; */
  }
  input::placeholder{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 15px;
    font-weight:100;
    color: #626262;
  }
  .buttons {
    align-self: center;
    width: 90%;
    border: 1px solid black;
    margin-left: 50px;
    margin-bottom: 30px;
  }

  .modal-head-wrapper{
    display:flex; 
    flex-direction:row;
     justify-content:space-between;
     align-items:center
    }

  @media screen and (max-width:850px){
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height:100%;
      background-color: rgba(0, 0, 0, 0.5); /* Transparent gray background */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    .rows {
      display: flex;
      flex-direction: column;
      justify-content:center;
      margin-block: 0px;
      margin-top : 0px;
    }
    .input-groups input{
      margin-top: 2rem;
    }
  }


  @media screen and (max-width:600px){
    .modal-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height:auto;
      background-color: rgba(0, 0, 0, 0.5); /* Transparent gray background */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }


  }