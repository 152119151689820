.registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6%;
    width: 100%;
    /* background-color: #D68B43; */
}
  .headingStyle{
    display: flex;
    flex-direction: row;
  }
  .p{
    margin-top: -2%;
    color: #6D6D6D;
    font-weight: 500;
  }

  .admin {
    color: #D68B43;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    font-size: 38px;
    margin-right: 10px;
  }
  .reg {
    color: black;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    font-size: 38px;
  }
  

  .pass-input-group{
    width: 100%;
  }

  .pass-input-group2{
    width: 100%;
  }

  .pass-input-group input {
    width: 90%;
  }

  .pass-input-group2 input {
    width: 90%;
    margin-bottom: 15%;
  }
  
  label {
    font-size: 13px;
    font-weight: 600;
  }

  input::placeholder{
    color: #9EA3A2;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: 200;
  }
  
  .belowButton {
    font-weight: 400;
    width: 40%;
    justify-content: center;
    align-items: center;

  }

  .belowButton p{
    margin-top: 1%;
    font-size: 11px;
    color: #6D6D6D;
    font-weight: 400;
    width: 60%;
    align-items: center;
    text-align: center;
    margin-left: 20%;
  }

  button{
    background-color: #FDC58F;
    border: none;
    padding: 15px 90px;
    font-weight: 600;
  }
  
 

  @media screen and (max-width:600px){
    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-inline: 200px;
      width: 85%;
      margin:0rem;
    }
    .belowButton p{
      width: 100%;
      margin-left:0%;
    }

    .pass-input-group{
      margin: 2rem 0rem;
    }

    .pass-input-group input{
      margin-bottom: 0rem;
      width: 100%;
    }
    .pass-input-group2 input{
      width: 100%;
    }
  }