.drawer-main {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cross-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem 0rem;
  width: 100%;
}

.cross {
  height: 30px;
  align-self: flex-end;
  margin-right: 2rem;
}

.drawer-options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 0rem;
  width: 100%;
}

.drawer-option-wrapper {
  margin: 1rem 0rem;
}

.drawer-option-wrapper span {
  color: white;
  font-size: 2rem;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin: 0rem 2rem;
}
