.registration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
    /* background-color: #D68B43; */
}
  .headingStyle{
    display: flex;
    flex-direction: row;
  }
  .p{
    margin-top: -2%;
    color: #6D6D6D;
    font-weight: 500;
  }

  .admin {
    color: #D68B43;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    font-size: 38px;
    margin-right: 10px;
  }
  .reg {
    color: black;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    font-size: 38px;
  }

  .input-grouppass{
  }
  
  

  .input-grouppass input {
    
    margin: 10px 7px;
    margin-top: 40px;
    margin-right: 115px;
    width: 90%;
  }
  .input-grouppass2{
  }
  
  

  .input-grouppass2 input {
    
    margin: 10px 30px;
    margin-top: 40px;
    padding: 2px 10px;
    margin-right: 260px;
    width: 80%;
    margin-bottom: 15%;
  }
  
  label {
    font-size: 13px;
    font-weight: 600;
    
  }

  input::placeholder{
    color: #9EA3A2;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: 200;
  }
  
  .belowButton {
    font-weight: 400;
    width: 40%;
    justify-content: center;
    align-items: center;

  }

  .belowButton p{
    margin-top: 1%;
    font-size: 11px;
    color: #6D6D6D;
    font-weight: 400;
    width: 60%;
    align-items: center;
    text-align: center;
    margin-left: 20%;
  }

  button{
    background-color: #FDC58F;
    border: none;
    padding: 15px 90px;
    font-weight: 600;
  }
  
  
 