.profile-wrapper{
  background-color:#FFFFFF;
  width: 80%;
  margin-top:50px;
  margin-bottom:50px;
  border-radius: 10px; 
  padding-inline:80px;
  padding-block: 8px
 }

.profile-page{ 
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.profileStyle {
    height: 20%;
    width: 16%;
    margin-top: 3vh;
    border: 4px solid #ffffff; /* Changed #fffff to #ffffff */
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
}

.profileInput {
  margin: 20px 0px;
  margin-top: 40px;
  padding: 7px 10px;
  margin-right: 260px;
  width: 30%;
  margin-bottom: 15%;
}

.profileInputmini{
  margin: 20px 0px;
  margin-top: 40px;
  padding: 7px 10px;
  width: 50%;
  margin-bottom: 15%;
}

.profileInputmini input{
  margin-top: 30px;
  width: 60%;
}

.profileInputmini label {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #333333;
}

.profileInput input{
    margin-top: 30px;
}

.profileInput label {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #333333;
}

.profile-right-cont{
  width: 55%; 
    margin-top: 4vh; 
    border-left: 2px solid #C6C6C6 ;
   padding-left: 3%
}

.right-cont-line{
  height:70vh;
  width: 2px; 
  background-color:#E4E7EC;
  position:absolute;
  right:30%
}

.profile-left-cont{  
  width:100%; 
  display: flex;
  flex-direction: column;
}

.profile-head-cont{
   display: flex; 
   flex-direction: row;
   align-items:center;
   height: 14v; 
   margin-right:10%
  }

  .profile-input-wrapper{
    display:flex;
    flex-direction:row;
    margin-top:-15%;
  }

  .profileInput-mini{
     width: 50%;
     display: flex;
     flex-direction: column;
  }

@media screen and (max-width:650px){
  .profile-wrapper{
    padding-inline:0px;
    width: 90%;
   }
  .profile-page{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .profile-right-cont{
      width: 75%; 
      margin-top: 4vh; 
      border-left: none;
     padding-left: 0%;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     display: flex;
  }
  .right-cont-line{
    display: none;
  }
  .profile-head-cont{
    display: flex; 
    flex-direction: column-reverse;
    align-items:flex-start;
    justify-content: center;
    height: auto; 
    margin-right:0%;
    width: 100%;
   }
   .profile-left-cont{  
    width:90%; 
    display: flex;
    flex-direction: column;
    
  }
  .profileInput {
    margin: 0px 0px;
    margin-top: 0px;
    padding: 0px 10px;
    margin-right: 0px;
    width: 100%;
  }
  .profileInput input{
    margin-top: 30px;
    width: 100%;
}
.profile-input-wrapper{
  display:flex;
  flex-direction:column;
  margin-top:5%;
  width: 100%;
}
.profileStyle {
  margin-bottom: 5vh;
}
.profileInputmini{
  margin: 0px 0px;
  margin-top: 0px;
  padding: 0px 10px;
  width: 90%;
  margin-bottom: 0%;
}

.profileInputmini input{
  margin-top: 0px;
  width: 100%;
}
}