.navbar-bars{
    display: none;
}

.nav-link-cont{
    display: 'flex';
    justify-content: 'center'
}

@media screen and (max-width:850px){
    .navbar-bars{
        display: block;
    }
    .logout-button{
        display: none;
    }
    .nav-link-cont{
        display: none;
    }
}