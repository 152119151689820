.tablesContainer{
    width: 100%;
    height: 60vh;
    overflow-x: auto;
}

.homeTable {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.204);
    border-collapse: collapse;
    width: 85rem;
}

.homeTable th {
    padding: 1rem 1rem;
    background-color: #272727;
    color: white;
    text-align: left;
    height: 0.5vh;
    font-weight: 400;
}

.homeTable td{
    padding: 1rem;
}

.home-top-container{
    display:flex;
    align-items:center;
    border:1px solid #ECE9F1;
    border-radius:10px;
    padding-inline:2px;
    width: 26%;
    padding-top:10px; 
    height:35px; 
    margin-top: 10px
}
.date-picker {
    cursor: pointer;
}
.pointer {
    cursor: pointer;
  }
.date-calender-wrapper
{ display: flex;
    flex-direction: row;
     margin-top:10px;
     }

.date-calender-picker{
    width:38%; 
    padding-inline:30px;
    padding-bottom:-100px;
    align-items: center;
    justify-content:center;
    height: 320px;
    border-radius: 20px;
    margin-bottom: 20px;
    box-shadow: 1px 20px 20px 1px rgba(0, 0, 0, 0.1);
}

.date-calender{
    display: flex;
    align-items:center;
     border:1px solid #ECE9F1;
     border-radius:10px;
     padding-inline:2px;
     width: 26%; 
     padding-top:10px;
     height: auto; 
     margin-top: 10px;
     cursor: pointer;
}

.home-brokers-section{ 
    width: 30%;
    padding-inline:30px;
    padding-bottom:-100px;
    align-items:center;
    justify-content: center;
    height:320px;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
}

@media screen and (max-width:650px){
    .home-top-container{
        display:flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;
        border:1px solid #ECE9F1;
        border-radius:10px;
        padding-inline:2px;
        width: 100%;
        padding-top:10px; 
        height:auto; 
        margin-top: 10px
    }
    .date-calender-wrapper
    { display: flex;
    flex-direction: column;
     margin-top:10px;
     }
    .date-calender-picker{
        width:80%; 
        height: auto;
    }
    .date-calender{
        display: flex;
        align-items:center;
        flex-direction: row;
         border:1px solid #ECE9F1;
         border-radius:10px;
         padding-inline:2px;
         height: 3rem;
         justify-content: center;
         width: 40%; 
         padding-top:10px;
         margin-top: 10px;
    }
    .home-brokers-section{ 
        width: 77%;
        height:auto;
    }
}