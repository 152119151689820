.profileStyle {
    height: 20%;
    width: 16%;
    margin-top: 3vh;
    border: 4px solid #ffffff; /* Changed #fffff to #ffffff */
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
}

.profileInput {
  margin: 20px 0px;
  margin-top: 40px;
  padding: 7px 10px;
  margin-right: 260px;
  width: 30%;
  margin-bottom: 15%;
}

.profileInput input{
    margin-top: 30px;
    width: 100%;
}

.profileInput label {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #333333;
}


@media screen and (max-width:650px) {
  .profileInput {
    margin: 0px 0px;
    margin-top: 0px;
    padding: 0px 10px;
    margin-right: 0px;
    width: 90%;
  }
  .profileInput input{
    margin-top: 10px;
    width: 100%;
}
}