.bulkmodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent gray background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.bulkmodal {
    background-color: white;
    padding-inline: 20px;
    margin-inline: 100px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 30%;
    height: 60%;
}

.bulkrows {
    position:absolute;
    background-color: #f2dcc6;
    width: 400px;
    align-self: center;
    /* margin: 30px 26px; */
    height: 150px;
    align-self: center;
}